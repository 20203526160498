import { Avatar } from '../../atoms/Avatar';
import { FixedButtonIconOnly, IFixedButtonIconOnlyProps } from '../../atoms/FixedButtonIconOnly';
import { Close } from '../../../assets/icons';
import styles from './Header.module.scss';

type THeaderProps = { source?: string } & Partial<Pick<IFixedButtonIconOnlyProps, 'disabled' | 'onClick'>>;

export const Header = ({ onClick, disabled = false, source }: THeaderProps): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.spacing} />
    <div className={styles.avatar}>
      <Avatar source={source} />
    </div>
    <div className={styles.button}>
      <FixedButtonIconOnly disabled={disabled} icon={<Close />} onClick={onClick} />
    </div>
  </div>
);
