import React, { forwardRef, ReactNode } from 'react';
import styles from './Scrollbar.module.scss';

interface IScrollbarProps {
  children: ReactNode;
}

// eslint-disable-next-line react/display-name
export const Scrollbar = forwardRef<HTMLDivElement, IScrollbarProps>(
  ({ children }, ref): JSX.Element => (
    <div ref={ref} className={styles.container}>
      {children}
    </div>
  ),
);
