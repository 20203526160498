import styles from './TextInput.module.scss';
import { TextField } from '../../atoms/TextField';
import { LiquidButtonIconOnly } from '../../atoms/LiquidButtonIconOnly';
import { Send } from '../../../assets/icons';

interface ITextInputProps {
  text: string;
  disabled?: boolean;
  errored?: boolean;
  placeholder?: string;
  onChangeCallBack: (value: string) => void;
  onSubmit?: () => void;
}
export const TextInput = ({
  text = '',
  disabled,
  errored,
  placeholder,
  onChangeCallBack,
  onSubmit,
}: ITextInputProps): JSX.Element => (
  <div className={styles.container}>
    <TextField
      text={text}
      disabled={disabled}
      errored={errored}
      placeholder={placeholder}
      onChangeCallBack={onChangeCallBack}
      onSubmit={onSubmit}
    />
    <div className={styles.buttonSend}>
      <LiquidButtonIconOnly icon={<Send />} onClick={onSubmit} />
    </div>
  </div>
);
