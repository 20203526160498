import React, { ReactElement } from 'react';
import styles from './LiquidButtonIconOnly.module.scss';

interface ILiquidButtonIconOnlyProps {
  icon: ReactElement;
  disabled?: boolean;
  onClick?: () => void;
}

export const LiquidButtonIconOnly = ({ icon, disabled = false, onClick }: ILiquidButtonIconOnlyProps): JSX.Element => (
  <button className={styles.container} disabled={disabled} type="button" onClick={onClick}>
    {icon}
  </button>
);
