import Image from 'next/image';
import styles from './Avatar.module.scss';

interface IAvatarProps {
  source?: string;
}

export const Avatar = ({ source }: IAvatarProps): JSX.Element => (
  <div className={styles.container}>
    <Image
      src={source ? `/images/${source}.png` : '/images/Taiki.png'}
      alt="avatar"
      width={200}
      height={150}
      priority
    />
  </div>
);
