import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { trackScreenViewEvent } from '@avita-co-jp/frontend-utils';
import { appState } from '../../states/app/appState';
import { useSetApp } from './set-app';

export type Query = {
  chat_key?: string;
  api_key?: string;
  scenario_id?: string;
};

export const useRootPage = () => {
  const [app, setApp] = useRecoilState(appState);
  useSetApp(app, setApp);

  useEffect(() => {
    if (app.isChatOpen) {
      window.parent.postMessage({ msg: 'OPEN_CHAT_BOT' }, '*');
      trackScreenViewEvent('open_chat');
    } else {
      window.parent.postMessage({ msg: 'CLOSE_CHAT_BOT' }, '*');
      trackScreenViewEvent('close_chat');
    }
  }, [app]);

  return { chatKey: app.chatKey, apiKey: app.apiKey, scenarioId: app.scenarioId, isChatOpen: app.isChatOpen };
};
