import React, { ReactElement } from 'react';
import styles from './FixedButtonOutline.module.scss';

interface IFixedButtonOutlineProps {
  disabled?: boolean;
  onClick?: () => void;
  label: string;
  icon?: ReactElement;
}

export const FixedButtonOutline = ({
  disabled = false,
  onClick,
  label,
  icon,
}: IFixedButtonOutlineProps): JSX.Element => (
  <button className={styles.container} disabled={disabled} onClick={onClick} type="button">
    {icon}
    <span>{label}</span>
  </button>
);
