import Image from 'next/image';
import styles from './ChatLaunchButton.module.scss';
import { useChatLaunchButton } from './ChatLaunchButton.hook';

interface IChatLaunchButtonProps {
  source?: string;
}

export const ChatLaunchButton = ({ source }: IChatLaunchButtonProps): JSX.Element => {
  const { handleClick } = useChatLaunchButton();
  return (
    <button className={styles.container} onClick={handleClick} type="button">
      {/* アドバンスクリエイト用の画像が使われているため、汎用化する */}
      <Image src={source || '/images/AvatarEmbed.png'} alt="avatar" width={230} height={160} />
    </button>
  );
};
