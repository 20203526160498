import { ChatBot } from '../components/templates/ChatBot';
import { useRootPage } from './hooks';

const App = () => {
  const { chatKey, apiKey, scenarioId, isChatOpen } = useRootPage();

  if (!chatKey || !scenarioId || !apiKey) return null;

  return <ChatBot isChatOpen={isChatOpen} />;
};

export default App;
