import { useCallback } from 'react';

interface IUseQuitConfirm {
  onClickFilledButton?: () => void;
  onClickOutlineButton?: () => void;
  hideChat: () => void;
  hideQuitConfirm: () => void;
}

export function useQuitConfirm({
  onClickFilledButton = () => {},
  onClickOutlineButton = () => {},
  hideChat = () => {},
  hideQuitConfirm = () => {},
}: IUseQuitConfirm) {
  const handleClickFilledButton = useCallback(() => {
    onClickFilledButton();
    hideQuitConfirm();
    hideChat();
  }, [hideChat, hideQuitConfirm, onClickFilledButton]);

  const handleClickOutlineButton = useCallback(() => {
    onClickOutlineButton();
    hideQuitConfirm();
  }, [hideQuitConfirm, onClickOutlineButton]);

  return {
    handleClickFilledButton,
    handleClickOutlineButton,
  };
}
