import styles from './Toast.module.scss';
import { Info, Warning } from '../../../assets/icons';

interface IToastProps {
  text: string;
  type: 'warning' | 'info';
}

export const Toast = ({ text, type = 'info' }: IToastProps): JSX.Element => (
  <div className={[styles.container, styles[type]].join(' ')}>
    {type === 'info' ? <Info /> : <Warning />}
    <span>{text}</span>
  </div>
);
