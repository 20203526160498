import styles from './QuitConfirm.module.scss';
import { FixedButtonFill } from '../../atoms/FixedButtonFill';
import { FixedButtonOutline } from '../../atoms/FixedButtonOutline';
import { useQuitConfirm } from './QuitConfirm.hooks';

interface IQuitConfirmProps {
  text: string;
  filledButtonText: string;
  disabledFilledButton?: boolean;
  onClickFilledButton?: () => void;
  outlineButtonText: string;
  disabledOutlineButton?: boolean;
  onClickOutlineButton?: () => void;
  isShow: boolean;
  hideChat: () => void;
  hideQuitConfirm: () => void;
}

export const QuitConfirm = ({
  text,
  filledButtonText,
  disabledFilledButton,
  onClickFilledButton,
  outlineButtonText,
  disabledOutlineButton,
  onClickOutlineButton,
  isShow = false,
  hideQuitConfirm,
  hideChat,
}: IQuitConfirmProps): JSX.Element | null => {
  const { handleClickFilledButton, handleClickOutlineButton } = useQuitConfirm({
    onClickFilledButton,
    onClickOutlineButton,
    hideQuitConfirm,
    hideChat,
  });

  if (!isShow) return null;

  return (
    <div className={styles.container}>
      <span>{text}</span>
      <div className={styles.buttons}>
        <div className={styles.fixedButton}>
          <FixedButtonFill disabled={disabledFilledButton} label={filledButtonText} onClick={handleClickFilledButton} />
        </div>
        <div className={styles.fixedButton}>
          <FixedButtonOutline
            disabled={disabledOutlineButton}
            label={outlineButtonText}
            onClick={handleClickOutlineButton}
          />
        </div>
      </div>
    </div>
  );
};
