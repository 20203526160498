import type { SetterOrUpdater } from 'recoil';
import qs from 'qs';
import { useEffect } from 'react';
import type { AppState } from '../../../states/app/appState';

type Query = {
  chat_key?: string;
  api_key?: string;
  scenario_id?: string;
};

export function useSetApp(app: AppState, setApp: SetterOrUpdater<AppState>) {
  useEffect(() => {
    if (app.chatKey && app.scenarioId && app.apiKey && app.siteOrigin) {
      return;
    }
    const queries: Query = qs.parse(window?.location.search || '', {
      ignoreQueryPrefix: true,
    });
    setApp({
      ...app,
      chatKey: queries.chat_key || '',
      apiKey: queries.api_key || '',
      scenarioId: queries.scenario_id || '',
      // document.referrer is available in iframe
      // window.location.href is available in local development
      siteOrigin: new URL(window.document.referrer || window.location.href).origin,
    });
  }, [app, setApp]);
}
