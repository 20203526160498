import React, { ReactElement } from 'react';
import styles from './FixedButtonIconOnly.module.scss';

export interface IFixedButtonIconOnlyProps {
  onClick?: () => void;
  disabled?: boolean;
  icon: ReactElement;
}

export const FixedButtonIconOnly = ({ onClick, disabled = false, icon }: IFixedButtonIconOnlyProps): JSX.Element => (
  <button className={styles.container} disabled={disabled} onClick={onClick} type="button">
    {icon}
  </button>
);
