import React, { ReactElement } from 'react';
import styles from './FixedButtonFill.module.scss';

export interface IFixedButtonFillProps {
  onClick?: () => void;
  disabled?: boolean;
  label: string;
  icon?: ReactElement;
  isElementCenter?: boolean;
}

export const FixedButtonFill = ({
  onClick,
  disabled = false,
  label,
  icon,
  isElementCenter = true,
}: IFixedButtonFillProps): JSX.Element => {
  const centerStatus = isElementCenter ? 'centerElement' : 'leftElement';
  return (
    <button
      className={[styles.container, styles[centerStatus]].join(' ')}
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {icon}
      <span>{label}</span>
    </button>
  );
};
