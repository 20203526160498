import { useRecoilState } from 'recoil';
import { useChatContext } from '../../../contexts/ChatContext';
import { appState } from '../../../states/app/appState';

export const useChatLaunchButton = () => {
  const [app, setApp] = useRecoilState(appState);
  const { startChat } = useChatContext();

  const handleClick = () => {
    setApp({ ...app, isChatOpen: true });
    startChat()
      .then(() => {})
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  };

  return { handleClick };
};
