import { useRef } from 'react';
import styles from './TextField.module.scss';
import { useTextField } from './TextField.hooks';

interface ITextFieldProps {
  text: string;
  disabled?: boolean;
  errored?: boolean;
  placeholder?: string;
  onChangeCallBack: (value: string) => void;
  onSubmit?: () => void;
}
export const TextField = ({
  text = '',
  disabled,
  errored,
  placeholder,
  onChangeCallBack,
  onSubmit,
}: ITextFieldProps): JSX.Element => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { handleOnChange, handleKeyDown } = useTextField(text, textareaRef, onChangeCallBack, onSubmit);

  return (
    <div className={styles.container}>
      <textarea
        ref={textareaRef}
        rows={1}
        className={[styles.textarea, errored ? styles.errored : ''].join(' ')}
        disabled={disabled}
        placeholder={placeholder}
        value={text}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};
