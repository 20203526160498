import React, { ReactNode } from 'react';
import styles from './ButtonReset.module.scss';

export interface IButtonResetProps {
  onClick?: () => void;
  disabled?: boolean;
  label: string | ReactNode;
}

export const ButtonReset = ({ onClick, disabled = false, label }: IButtonResetProps): JSX.Element => (
  <button className={styles.container} disabled={disabled} onClick={onClick} type="button">
    {label}
  </button>
);
