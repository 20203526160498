import { ChangeEvent, RefObject, useCallback, useEffect } from 'react';

export const useTextField = (
  text: string,
  textareaRef: RefObject<HTMLTextAreaElement>,
  onChangeTextArea: (value: string) => void,
  onSubmit?: () => void,
) => {
  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onChangeTextArea(e.target.value);
    },
    [onChangeTextArea],
  );

  const handleKeyDown = useCallback(
    (keyEvent: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (onSubmit && keyEvent.key === 'Enter' && (keyEvent.ctrlKey || keyEvent.metaKey)) {
        onSubmit();
      }
    },
    [onSubmit],
  );

  useEffect(() => {
    const { current } = textareaRef;
    if (current) {
      current.style.height = '0px';
      current.style.height = `${current.scrollHeight}px`;
    }
  }, [textareaRef, text]);

  return {
    handleOnChange,
    handleKeyDown,
  };
};
